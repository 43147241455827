import React from 'react';

const MonitorLibraryContainer = Loader.loadBusinessComponent("MonitorLibrary", "MonitorLibraryContainer");


const outSiderMonitorLibrary = () => {
  const sceneCode = BaseStore.user.appInfo.sceneCode;
  const monitorLabel = Dict.map.monitorLabel[sceneCode];
  const libTypeInfo = {
    libType: 2,
    libLabel: monitorLabel.outsider.libLabel,
    libPeople: monitorLabel.outsider.libPeople,
    authAction: 'outsiderLibraryManage',
    moduleName: 'outsiderLibraryView',
  }

  return (
    <MonitorLibraryContainer 
      libTypeInfo={libTypeInfo}
    />
  )
}

export default outSiderMonitorLibrary